* , *:before, *:after{
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 var(--header-height) 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color)
}

h1, h2, h3, h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold)
}

img {
  max-width: 100%;
  height: auto;
}

